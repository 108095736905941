<template>
  <div>
    <app-page-detail page-title="Detail BA Kronologis ABD" page-toolbar-title="Detail BA Kronologis ABD" :record-not-found="notFound">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="goBack()"></action-button>
      </template>

      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.customer_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.customer_name" placeholder="Customer Name" label="Customer Name" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense disabled readonly v-model="baabd_header.contract_value" placeholder="Nilai Kontrak" label="Nilai Kontrak" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.invoice_value" placeholder="Nilai Invoice" label="Nilai Invoice" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.payment_value" placeholder="Nilai Payment" label="Nilai Payment" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense disabled readonly v-model="baabd_header.last_paid_date" placeholder="Last Paid Date" label="Last Paid Date" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.balance_ar_value" placeholder="Balance AR" label="Balance AR" outlined hide-details class="mb-2" reverse type="number"></app-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>
              </card-expansion>
              <card-expansion title="Rincian Surat" class="mb-6">
                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense disabled readonly v-model="baabd_header.warning_letter_date1" placeholder="Teguran 1" label="Teguran 1" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.warning_letter_date2" placeholder="Teguran 2" label="Teguran 2" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.warning_letter_date3" placeholder="Teguran 3" label="Teguran 3" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.warning_letter_date4" placeholder="Teguran 4" label="Teguran 4" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <app-text-field dense disabled readonly v-model="baabd_header.legal_demand_letter1" placeholder="Somasi 1" label="Somasi 1" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.legal_demand_letter2" placeholder="Somasi 2" label="Somasi 2" type="date" outlined hide-details class="mb-2"></app-text-field>
                    <app-text-field dense disabled readonly v-model="baabd_header.legal_demand_letter3" placeholder="Somasi 3" label="Somasi 3" type="date" outlined hide-details class="mb-2"></app-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Kronologis Penagihan" class="mb-6" :loading="loading" no-padding>
                <v-row>
                  <v-col>
                    <v-data-table :items="baabd_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0">
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn @click="deleteDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn @click="showDialogEditDetail(item)" class="mr-2" icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="List Approval" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <ApprovalList v-bind:data-list="baabd_approval"></ApprovalList>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :baabd_header="baabd_header"></document-info>
          <app-comment :document_id="parseInt(baabd_header.id)" :source_document="baabd_header.source_document" :document_no_="baabd_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
import ActionButton from "./components/ActionButton.vue";
// import SetCloseDocument from "./components/SetCloseDocument.vue";

export default {
  components: {
    // CommentList,
    ApprovalList,
    DocumentInfo,
    ActionButton,
    // SetCloseDocument,
  },
  data() {
    return {
      id: null,
      loading: false,
      data_detail: {},
      baabd_header: {},
      baabd_detail: [],
      baabd_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: null,

      datatable_detail: {
        loading: false,
        headers: [
          { text: "Tanggal", value: "line_date" },
          { text: "Deskripsi", value: "line_description" },
        ],
      },
      dialogAddDetail: false,
      dialogAddDetailData: {},
      dialogEditDetail: false,
      dialogEditDetailData: {},
      dialogEditSelectedIndex: null,
      notFound: false,
    };
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
    vatValue() {
      var total = 0;
      this.baabd_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return !isNaN(total * (this.baabd_header.vat / 100)) ? total * (this.baabd_header.vat / 100) : 0;
    },
    totalAmount() {
      var total = 0;
      this.baabd_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return total;
    },
    dppAmount() {
      var total = 0;
      this.baabd_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });

      if (this.baabd_header.vat == 0) {
        return 0;
      }

      const vat1 = 11 / 100;
      const vat2 = this.baabd_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return !isNaN(rs) ? rs : 0;
    },
    grandTotal() {
      var total = 0;
      this.baabd_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      // tambahkan pajak
      // total += total * (this.baabd_header.vat / 100);

      const vat1 = 11 / 100;
      const vat2 = this.baabd_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return isNaN(total + rs) ? 0 : total + rs;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "baabd/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("baabd/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.baabd_header = res.data.data.baabd_header;
          this.baabd_detail = res.data.data.baabd_detail;
          this.baabd_approval = res.data.data.baabd_approval;

          this.selected_campaign = this.baabd_header.campaign_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          console.log("error.response", error.response);

          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
